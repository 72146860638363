/* eslint-disable react-hooks/rules-of-hooks */
import {
  loginWithCode,
  refreshCode,
  UserAccountContext,
  UserAccountModal,
} from '@finn/ua-auth';
import { AvailableFilters, Brand } from '@finn/ua-vehicle';
import {
  CookieKeys,
  getLocaleFromContext,
  Locale,
  trackEvent,
  useSession,
} from '@finn/ui-utils';
import Cookies from 'js-cookie';
import isArray from 'lodash/isArray';
import { GetStaticProps, NextPage } from 'next';
import { useRouter } from 'next/router';
import React, { ReactNode, useContext, useEffect } from 'react';

import { useRetentionHomePageData } from '~/modules/home/useRetentionHomePageData';
import { BasePageData } from '~/types/cosmicModules';
import { Review } from '~/types/reviews';
import PageBodyBuilder from '~/ui-modules/PageBodyBuilder';
import { addSentryRenderPageBreadcrumb } from '~/utils/sentry';

import { USDeprecationNoticeContainer } from '../components/RedirectionContainer/USDeprecationNoticeContainer';
import { getLandingPageGetServerSideProps } from '../utils/landingPage';

export interface IProps {
  pageData: BasePageData;
  reviews: Review[];
  brands: Brand[];
  renderMetaContent?: () => ReactNode;
  locale: string;
  allAvailableFilters?: AvailableFilters;
}

const homePageldScript = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  logo: '',
  name: 'FINN',
  url: 'https://www.finn.com/de-DE',
  sameAs: [
    'https://www.finn.com/de-DE',
    'https://www.youtube.com/c/FINNauto',
    'https://www.instagram.com/finn.auto/',
    'https://www.facebook.com/finn.auto.abo',
    'https://www.linkedin.com/company/finn-auto',
  ],
};

export const HomePage: NextPage<IProps> = ({
  pageData,
  reviews,
  brands,
  locale,
  renderMetaContent,
}) => {
  if (!pageData?.metadata?.modules) return null;

  const router = useRouter();
  const [session] = useSession();
  const { setModalStatus } = useContext(UserAccountContext);
  const { retentionPageData } = useRetentionHomePageData();

  const removeUrlQuery = () => {
    router.replace(`/`, undefined, {
      shallow: true,
    });
  };

  useEffect(() => {
    const login = async (code: string) => {
      await loginWithCode(code);
      trackEvent('Account Logged In', { loginMethod: 'code' });
      await refreshCode();
    };

    const verificationCode = Cookies.get(CookieKeys.AUTH_EMAIL_VERIFIED);
    if (verificationCode) {
      login(verificationCode);
      setModalStatus({
        isModalOpen: true,
        modalType: UserAccountModal.EMAIL_VERIFIED_SUCCESSFULLY,
      });
      Cookies.remove(CookieKeys.AUTH_EMAIL_VERIFIED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  useEffect(() => {
    const gotoNext = (path: string | string[] | undefined) => {
      const nextPath = isArray(path) ? path[0] : path;
      if (nextPath) {
        const fullUrl = nextPath?.startsWith('/portal')
          ? `https://${window.location.host}/${nextPath}`
          : `https://${window.location.host}/${router.locale}/${nextPath}`;
        window.location.replace(fullUrl);
      } else {
        router.replace('/');
      }
    };
    const { login, next, modal } = router.query;
    if (login === 'true' && session?.user?.email_verified) {
      gotoNext(next);
    }

    if (modal) {
      setModalStatus({
        isModalOpen: true,
        modalType: modal as UserAccountModal,
        options: {
          onClose: () => {
            // To prevent modal from reshowing on page refresh
            removeUrlQuery();
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, session?.user?.email_verified]);

  addSentryRenderPageBreadcrumb('/pages/index', 'Index');

  const jsonLdContentArr =
    locale === Locale.GERMAN_GERMANY ? [JSON.stringify(homePageldScript)] : [];

  return (
    <USDeprecationNoticeContainer>
      <PageBodyBuilder
        data={retentionPageData ? retentionPageData : pageData}
        reviews={reviews}
        brands={brands}
        renderMetaContent={renderMetaContent}
        jsonLdContentArr={jsonLdContentArr}
      />
    </USDeprecationNoticeContainer>
  );
};

export const getStaticProps: GetStaticProps = async (ctx) => {
  const locale = getLocaleFromContext(ctx);
  const slug = 'home-new';

  const props = await getLandingPageGetServerSideProps(slug, locale);

  return {
    props: {
      ...props,
      locale,
    },
  };
};

export default HomePage;
