import { Button } from '@finn/design-system/atoms/button';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import { Locale } from '@finn/ui-utils';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

type Props = {
  children?: ReactNode;
};
export const USDeprecationNoticeContainer = ({ children }: Props) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const router = useRouter();
  const { locale, isReady } = router;

  const intl = useIntl();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const referrer = urlParams.get('referrer');
    const isNotInUSA = locale !== Locale.ENGLISH_USA;
    const isUSAReferer = referrer === Locale.ENGLISH_USA;

    if (isUSAReferer && isNotInUSA) {
      setIsModalOpened(true);
    }
  }, [isReady]);

  const handleModalClose = () => {
    setIsModalOpened(false);
    // clean query params from the URL
    router.replace(router.pathname, undefined, { shallow: true });
  };

  return (
    <>
      {children}
      <Modal open={isModalOpened} variant="small">
        <ModalContent renderHeader={() => null}>
          <ModalHeader>
            <ModalTitle>
              {intl.formatMessage({ id: 'general.usRedirectionModal.title' })}
            </ModalTitle>
          </ModalHeader>
          <p>{intl.formatMessage({ id: 'general.usRedirectionModal.body' })}</p>
          <ModalFooter>
            <Button onClick={handleModalClose} className="w-full">
              {intl.formatMessage({ id: 'general.usRedirectionModal.button' })}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
